import { useState, useEffect } from "react";
import "./App.css";

const _apiKey = "61a4c4be1fd14ed4bb88e13ec4e3a102";

const urlRatesCurrentDate = `https://openexchangerates.org/api/latest.json?app_id=${_apiKey}`;

const Wrapper = () => {
  const [value, setValue] = useState("");
  const [result, setResult] = useState("___");
  const [moneySymbolTo, setMoneySymbolTo] = useState("");
  const [moneySymbolFrom, setMoneySymbolFrom] = useState("");
  const [targetCoinFrom, setTargetCoinFrom] = useState();

  const handleValueInputChange = (event) => {
    if (event.target.value < 0) {
      alert('Введите число больше 0')
    } else {
      setValue(event.target.value);
    }
    
  };

  function handleFromCoin(e) {
    activity(e);
    setTargetCoinFrom((targetCoinFrom) => e.target.innerText);
    changeMoneySymbol(e.target.innerText);
  }

  function handleToCoin(e, coinRate) {
    activity(e);
    const targetCoin = e.target.innerText;
    if (targetCoin === targetCoinFrom) {
      setResult((result) => value);
    }
    switch (targetCoin) {
      case "RUB":
        setResult((result) =>
          ((value / rates[coinRate]) * rates["RUB"]).toFixed(2)
        );
        changeMoneySymbol(targetCoinFrom, " Руб");
        break;
      case "EUR":
        setResult((result) =>
          ((value / rates[coinRate]) * rates["EUR"]).toFixed(2)
        );
        changeMoneySymbol(targetCoinFrom, " €");
        break;
      case "GEL":
        setResult((result) =>
          ((value / rates[coinRate]) * rates["GEL"]).toFixed(2)
        );
        changeMoneySymbol(targetCoinFrom, " ₾");
        break;
        case "TRY":
        setResult((result) =>
          ((value / rates[coinRate]) * rates["TRY"]).toFixed(2)
        );
        changeMoneySymbol(targetCoinFrom, " ₺");
        break;
      default:
        setResult((result) => (value / rates[coinRate]).toFixed(2));
        changeMoneySymbol(targetCoinFrom, " $");
    }
  }

  function handleResetResult(e) {
    setValue((value) => "");
    setResult((result) => "___");
    setMoneySymbolFrom((moneySymbolFrom) => "");
    setMoneySymbolTo((moneySymbolTo) => "");
    allButtonsFrom.forEach((item) => {
      item.classList.remove("activityTo");
      item.classList.remove("activityFrom");
    });
  }

  const allButtonsFrom = document.querySelectorAll(".controls__buttons");

  const activity = (e) => {
    if (e.target.parentElement.classList.contains("controls__from")) {
      allButtonsFrom.forEach((item) => {
        item.classList.remove("activityTo");
      });
      return e.target.classList.add("activityTo");
    } else {
      allButtonsFrom.forEach((item) => {
        item.classList.remove("activityFrom");
      });
      return e.target.classList.add("activityFrom");
    }
  };

  function changeMoneySymbol(symbolFrom = "", symbolTo = "") {
    setMoneySymbolTo((moneySymbolTo) => symbolTo);
    setMoneySymbolFrom((moneySymbolFrom) => {
      switch (symbolFrom) {
        case "RUB":
          return " P";
          break;
        case "EUR":
          return " €";
          break;
        case "GEL":
          return " ₾";
          break;
          case "TRY":
          return " ₺";
          break;
        default:
          return " $";
      }
    });
  }

  const [date, setDate] = useState();
  const [rates, setRates] = useState("");

  const currentDate = new Date(date * 1000).toLocaleDateString();

  useEffect(() => {
    const fetchDataCurrentRates = async () => {
      const result = await fetch(urlRatesCurrentDate);
      result
        .json()
        .then((result) => {
          setRates((rates) => result.rates);
          setDate((date) => result.timestamp);
        })
        .catch((error) => console.log("error", error));
    };
    fetchDataCurrentRates();
  }, []);

  return (
    <div className="app">
      <h2 className="title">Калькулятор Валют:</h2>
      <h3 className="date">{currentDate}</h3>
      <input
        type="number"        
        className="counter"
        placeholder="_____"
        value={value}
        onChange={handleValueInputChange}
      ></input>
      <div className="coin__after"
        data-content={moneySymbolFrom}>
        
      </div>
      <div className="counter answer">
        {result}
        {moneySymbolTo}
      </div>
      <div className="controls controls__from">
        <p>From:</p>
        <button
          className="controls__buttons"
          onClick={(e) => handleFromCoin(e)}
        >
          USD
        </button>
        <button
          className="controls__buttons"
          onClick={(e) => handleFromCoin(e)}
        >
          EUR
        </button>
        <button
          className="controls__buttons"
          onClick={(e) => handleFromCoin(e)}
        >
          GEL
        </button>
        <button
          className="controls__buttons"
          onClick={(e) => handleFromCoin(e)}
        >
          TRY
        </button>
        <button
          className="controls__buttons"
          onClick={(e) => handleFromCoin(e)}
        >
          RUB
        </button>
      </div>
      <div className="controls controls__to">
        <p>To:</p>
        <button
          className="controls__buttons"
          onClick={(e) => handleToCoin(e, targetCoinFrom)}
        >
          USD
        </button>
        <button
          className="controls__buttons"
          onClick={(e) => handleToCoin(e, targetCoinFrom)}
        >
          EUR
        </button>
        <button
          className="controls__buttons"
          onClick={(e) => handleToCoin(e, targetCoinFrom)}
        >
          GEL
        </button>
        <button
          className="controls__buttons"
          onClick={(e) => handleToCoin(e, targetCoinFrom)}
        >
          TRY
        </button>
        <button
          className="controls__buttons"
          onClick={(e) => handleToCoin(e, targetCoinFrom)}
        >
          RUB
        </button>
      </div>
      <button
        className="controls__buttons reset"
        onClick={(e) => handleResetResult(e)}
      >
        RESET
      </button>
    </div>
  );
};

function App() {
  return (
    <>
      <Wrapper />
    </>
  );
}

export default App;
