import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Header } from "./App";

// const text= 'Hello World';

// const elem = (
//   <div>
//     <h2>Text: {text}</h2>
//     <input type="text" />
//     <button />
//   </div>
// );

// const elem2 = React.createElement(
//   "h2",
//   { className: "greetings" },
//   "Hello World2!"
// ); // никто таким синтаксисом не пользуется

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App/>);
